import {Lists} from '../../utils/lists';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import {registrationDataAtom, registrationStepAtom, showIntroAtom} from '../../pages/Registration';
import React, {useMemo} from 'react';
import SubjectsComponent from '../SubjectsComponents/SubjectsComponent';
import {selectedSubjectAtom} from '../SubjectsComponents/utils';
import {RegistrationMessages} from './messages';
import SubjectInfoNote from '../commons/SubjectInfoNote';
import {sessionAtom} from '../../state/session';
import {setRecoil} from '../../state/recoilNexus';
import AddSubjectButton from '../commons/AddSubjectButton';
import {fi} from '../../utils/helpers';

let qualificationsCopied = false;
const SubjectsStep = () => {
	const selectedSubject = useRecoilValue(selectedSubjectAtom);
	const [activeStep, setActiveStep] = useRecoilState(registrationStepAtom);
	const storedData = useRecoilValue(registrationDataAtom('qualifications'));
	const user = useRecoilValue(sessionAtom);
	const showIntro = useSetRecoilState(showIntroAtom);

	const stepHeader = useMemo(() => {
		if (!user) return '';
		let roles = user.getRoles();

		if (roles.isAssessor) {
			return RegistrationMessages.SubjectsStepHeaderAssessor;
		}
		if (roles.isExamsOfficer) {
			return RegistrationMessages.SubjectsStepHeaderExamsOfficer;
		}
		if (roles.isCoordinator) {
			return RegistrationMessages.SubjectsStepHeaderCoordinator;
		}
		if (roles.isAdministrator) {
			return RegistrationMessages.SubjectsStepHeaderAdministrator;
		}
		return RegistrationMessages.SubjectsStepHeader;
	}, [user]);

	const goToNextStep = () => {
		setActiveStep(old => old + 1);
	};
	const goBack = () => {
		if (activeStep === 0) {
			showIntro(true);
		} else {
			setActiveStep(old => old - 1);
		}
	};
	const disableButton = useMemo(() => {
		return !!(selectedSubject && (selectedSubject.qualification === '' || selectedSubject.qualification));
	}, [selectedSubject]);

	const userData = useMemo(() => {
		if (!user) return [];
		if (user.preferences && user.shouldUpdatePreferences() && !qualificationsCopied) {
			setRecoil(registrationDataAtom('qualifications'), user.getQualifications());
			qualificationsCopied = true;
		}
		return storedData;
	}, [storedData, user]);

	const displayInfo = useMemo(() => {
		if (!user) return false;
		let roles = user.getRoles();

		if (roles.isAssessor || roles.isRestrictedAccess || roles.isExamsOfficer || roles.isTrial || roles.isCoordinator || roles.isAdministrator) {
			return false
		}
		return true
	},[user])

	const disableContinueButton = Lists.default(userData).length === 0;
	return (
		<>
			<div className="ml-32 mr-32">
				{fi(displayInfo, <SubjectInfoNote/>)}
				<div className="mt-32">
					<div className="flex-row-space-between mb-16">
						<p className="text-24 text-regular" data-testid='step-header'> {stepHeader}</p>
						<AddSubjectButton disabled={disableButton || Lists.default(userData).length === 0}
										  shouldNavigate={false} registerMode={true}/>
					</div>
					<SubjectsComponent userData={userData}/>
				</div>
			</div>
			<Divider/>
			<div className="flex-row-end pt-16 pl-16 pb-16 mr-32" data-testid="actions">
				<Button variant="text" data-testid="back-btn" onClick={goBack} size="small">
					Back
				</Button>
				<Button variant="contained" size="small" data-testid="continue-btn"
						onClick={goToNextStep} disabled={disableContinueButton}>
					Continue
				</Button>
			</div>
		</>

	);
};
export default SubjectsStep;