export enum RegistrationMessages {
    RegistrationHeader = "Complete your profile",
    /** complete profile screen **/
    CompleteProfileExamOfficer = `Next we'll ask for some extra information. This will give you easy access to resources
                that are relevant to you.`,
    CompleteProfileTeacher = `Next we'll ask for some extra information. This will give you easy access to resources
                that are relevant to you and the courses you teach.`,
    CompleteProfileInfoNote = `To access this service, you'll need to complete your profile.`,

    /** steps **/
    AboutYouStepHeader = "What is your job title?",
    SubjectsStepHeader = "Which subjects and units do you teach?",
    SubjectsStepHeaderExamsOfficer = "Which subjects do you want to add to your profile?",
    SubjectsStepHeaderAssessor = "Which subjects do you assess?",
    SubjectsStepHeaderCoordinator = "Which subjects do you want to add to your profile? You can change this later.",
    SubjectsStepHeaderAdministrator = "Which subjects do you want to add to your profile? You can change this later.",
    CommunicationStepHeader = "Communication preferences",
    /** trial messages **/
    TrialTeacherHeader = "Great news",
    TrialTeacherLineOne = `Since you already teach an OCR qualification, all you need to do is contact
                    your Exams officer.
                    They will be able to help you get full access to Teach Cambridge.`,
    TrialExamOfficerHeader = `Access for exam officers`,
    TrialExamOfficerSubheaderOne = `If your centre already teaches OCR qualifications`,
    TrialExamOfficerContentOneStart = `If your centre already teaches any of the subjects currently available on Teach Cambridge,
                    you should have already received an email from us inviting you to register with My Cambridge.
                    If you haven't yet registered, please contact our`,
    TrialExamOfficerContentOneEnd = `so we can re-send the invite to you.`,
    TrialExamOfficerContentTwo = `If you've already registered with My Cambridge, you can invite more teachers to use Teach
                    Cambridge
                    from the My Cambridge service. You can also personalise the information you see in Teach
                    Cambridge
                    by adding and managing your subjects.`,
    TrialExamOfficerSubheaderTwo = `If your centre is considering switching to OCR`,
    TrialExamOfficerContentThree = `Teachers at your centre can create a trial access account for themselves. Trial access is
                    designed
                    to give teachers a taste of the Teach Cambridge experience and enables them to see some of the
                    types
                    of support available to OCR teachers.`,
    TrialCommunicationLabel = "Are you happy for our customer development team to contact you?",
    TrialJobLabel = `What's your job title?`,
    TrialTeachingLabel = `Do you currently teach OCR qualifications?`


}
