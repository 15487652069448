import styled from "@emotion/styled";
import {HBox} from "../widgets/commons/HBox";
import {VBox} from "../widgets/commons/VBox";

export const ListModeCard = styled(VBox)`
    
`

export const ListModeCardDetails = styled(VBox)`
    width: 100%;
    font-size: 0.9em;
    padding: 0;
    align-items: flex-start;
    height: 0;
    transition: height 0.1s ease-in-out;
    overflow: hidden;

    &.toggled {
        height: unset;
        padding: 0 16px 16px 88px;
        border-bottom: 1px solid var(--color-border-light);
    }
    
    p:empty {
        margin-top: -16px;
    }
`

export const ListModeCardInfo = styled(HBox)`
    padding: 16px;
    border-bottom: 1px solid var(--color-border-light);
    align-items: center;
    gap: 8px;
    min-width: 0;
    flex:1;
    width: 100%;
    
    &:last-of-type {
        border-bottom: none;
    }
    
    & > * {
        min-width: 0;
    }
    
    
    &:hover {
        background: var(--color-blue-background);
        
        .list-actions {
            opacity: 1;
            pointer-events: all;
        }
    }
    
    div.title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
        min-width: 0;
        display: flex;
        align-items: center;
        gap: 12px;
        width: 300px;

        h5 {
			line-height: 18px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            
            .highlighted-word {
                background-color: #cce3f0;
            }

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
        
        .flag {
            position: relative;
            margin: 0;
            display: flex;
            align-items: center;
            gap: 8px;

            span {
                border-radius: 4px;
                margin: 0;
            }
        }
    }
    
    .selector {
        display: block;
        width: 23px;
        height: 23px;
        position: relative;
        margin-top: -2px;
        
        span {
            position: relative;
            margin: 0;
            padding: 0;
            left: 0;
            top: 0;
        }
    }

    .icon {
        margin-bottom: -3px;
        svg {
            height: 28px;
            
            circle {
                display: none;
            }
        }
    }
`